<template>
  <div class="info px-8 py-2">
    <ul class="flex border-b p-4">
      <li class="-mb-px mr-1">
        <a
          @click="selectedTab = 'Manage'"
          :class="{
            'tab-selected': selectedTab === 'Manage',
            'tab-default': selectedTab !== 'Manage'
          }"
          href="#"
          >Manage User Roles</a
        >
      </li>
      <li class="mr-1">
        <a
          @click="selectedTab = 'Create'"
          :class="{
            'tab-selected': selectedTab === 'Create',
            'tab-default': selectedTab !== 'Create'
          }"
          href="#"
          >Create User</a
        >
      </li>
    </ul>
    <div v-if="selectedTab === 'Create'">
      <form class="w-full max-w-lg m-auto mt-8">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              First Name
            </label>
            <input
              :class="{ 'border-red-500': !requirements['grid-first-name'] }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Jane"
            />
            <p
              v-if="!requirements['grid-first-name']"
              class="text-red-500 text-xs italic"
            >
              Please fill out this field.
            </p>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Last Name
            </label>
            <input
              :class="{ 'border-red-500': !requirements['grid-last-name'] }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Doe"
            />
            <p
              v-if="!requirements['grid-last-name']"
              class="text-red-500 text-xs italic"
            >
              Please fill out this field.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              :class="{ 'border-red-500': !requirements['grid-email'] }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-email"
              type="text"
              placeholder="janedoe@gmail.com"
            />
            <p
              v-if="!requirements['grid-email']"
              class="text-red-500 text-xs italic"
            >
              Please fill out this field.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Password
            </label>
            <input
              :class="{ 'border-red-500': !requirements['grid-password'] }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="password"
              placeholder="******************"
            />
            <p
              v-if="!requirements['grid-password']"
              class="text-red-500 text-xs italic"
            >
              Please fill out this field.
            </p>
            <!-- <p class="text-gray-600 text-xs italic">Auto generate instead?</p> -->
          </div>
          <button
            @click="createAccount"
            class="mx-auto my-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
    <div v-if="selectedTab === 'Manage'" class="grid grid-cols-1 gap-4">
      <div class="mb-4 mt-4">
        <input
          v-model="userSearch"
          class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="User Search"
        />
      </div>
      <div>
        <button
          v-for="role in roleList"
          :key="role"
          :data-role="role"
          @click="filterRole"
          class="text-blue-500 hover:text-blue-800 mx-1 bg-gray-200 p-1 m-1"
          :class="{ 'bg-green-200': role === roleSelected }"
        >
          {{ roles[role] }}
          <span v-if="role === roleSelected">{{ filteredUsers.length }}</span>
        </button>
      </div>
      <div class="p-8">
        <table class="table-auto">
          <thead>
            <tr>
              <th
                class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100"
              >
                Select
              </th>
              <th class="px-4 py-2">Email</th>
              <th class="px-4 py-2">Name</th>
              <th class="px-4 py-2">Roles</th>
              <th class="px-4 py-2">Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, i) in filteredUsers"
              :key="user.uid"
              :class="{ 'bg-gray-100': i % 2 === 1 }"
            >
              <td class="border-dashed border-t border-gray-200 px-3">
                <label
                  class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
                >
                  <input
                    :checked="user.metadata.checked"
                    type="checkbox"
                    class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
                    :name="user.uid"
                    @click="updateChecked($event, user.uid)"
                  />
                </label>
              </td>
              <td class="border px-4 py-2">{{ user.email }}</td>
              <td class="border px-4 py-2">
                {{ user.displayName || "Unknown" }}
              </td>
              <td class="border px-4 py-2">
                {{ Object.keys(user.customClaims).join(", ") }}
              </td>
              <td class="border px-4 py-2">
                {{
                  user.metadata.lastRefreshTime ||
                    user.metadata.lastRefreshTime ||
                    user.metadata.creationTime
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="isUserSelected" class="flex space-x-2 ml-8">
        <div class="relative">
          <select
            v-model="role"
            class="block appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option></option>
            <option v-for="(role, value) in roles" :key="role" :value="value">
              {{ role }}
            </option>
            <option value="admin">Admin</option>
            <option value="all-meetings">All Meetings</option>
            <option value="current-meetings">Current Meetings</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <button class="btn btn-blue" @click="addRole">Add Role</button>
        <button class="btn btn-blue space-x-1" @click="removeRole">
          Remove Role
        </button>
        <br />
        <button class="btn btn-red" style="color:red;" @click="removeUser">
          Remove User
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Info",
  data: function() {
    return {
      role: "",
      userSearch: "",
      roleSelected: "",
      selectedTab: "Manage",
      requirements: {
        "grid-first-name": true,
        "grid-last-name": true,
        "grid-email": true,
        "grid-password": true
      }
    };
  },
  beforeCreate() {
    if (!this.$store.state.admin.users.length) {
      this.$store.dispatch("admin/getAllUsers");
    }
  },
  computed: {
    ...mapState("admin", ["users", "roles"]),
    ...mapGetters({
      isUserSelected: "admin/isUserSelected",
      roleList: "admin/roleList",
      userIndexMap: "admin/userIndexMap"
    }),
    trimmedUserSearch() {
      return this.userSearch.trim().toLowerCase();
    },
    filteredUsers() {
      let returnedUsers = this.users;
      if (this.trimmedUserSearch?.length > 1 || this.roleSelected) {
        returnedUsers = returnedUsers.filter(user => {
          let outcome = true;
          if (this.trimmedUserSearch?.length > 1) {
            outcome =
              user.email.toLowerCase().indexOf(this.trimmedUserSearch) >= 0 ||
              user?.displayName
                ?.toLowerCase()
                .indexOf(this.trimmedUserSearch) >= 0;
          }
          if (outcome && this.roleSelected) {
            outcome = user?.customClaims[this.roleSelected];
          }
          return outcome;
        });
      }
      return returnedUsers;
    }
  },
  methods: {
    createAccount() {
      let check = true;

      Object.keys(this.requirements).map(key => {
        if (!document.getElementById(key).value) {
          this.requirements[key] = false;
          check = false;
        } else {
          this.requirements[key] = document.getElementById(key).value;
        }
      });

      if (this.requirements["grid-password"].length < 6) {
        alert("Password must be over 6 characters long");
        return false;
      }

      if (check) {
        this.$store
          .dispatch("admin/createUser", this.requirements)
          .then(user => {
            if (user) {
              console.log(user);
              Object.keys(this.requirements).map(key => {
                document.getElementById(key).value = "";
              });
            }
          });
      }
    },
    removeUser() {
      let proceed = confirm(
        "Are you sure you want to delete the selected User(s)?"
      );
      if (proceed) {
        this.$store.dispatch("admin/removeUser");
      }
    },
    filterRole() {
      this.roleSelected = event.target.getAttribute("data-role");
    },
    updateChecked(event, uid) {
      this.$store.dispatch("admin/updateChecked", {
        checked: event.currentTarget.checked,
        index: this.userIndexMap[uid]
      });
    },
    addRole() {
      this.$store.dispatch("admin/addRole", this.role);
    },
    removeRole() {
      this.$store.dispatch("admin/removeRole", this.role);
    }
  }
};
</script>

<style scoped>
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}
.tab-selected {
  @apply bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold;
}
.tab-default {
  @apply bg-white inline-block py-2 px-4 text-blue-500 font-semibold;
}
.tab-default:hover {
  @apply text-blue-800;
}
</style>
